import { Button, Col, Row } from 'antd';

import { CardItem, QuestionItem } from '../../models/chatItem';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import './card-list-message.scss';
import { useEffect, useState } from 'react';

interface CardListMessageProps {
  text: string;
  cards: CardItem[];
  onClickBtnMessage: (answerItem: QuestionItem) => void;
}

export const CardListMessage = ({ text, cards, onClickBtnMessage }: CardListMessageProps) => {
  const [cardList, setCardList] = useState<CardItem[]>([]);
  const [selectedCard, setSelectedCard] = useState<CardItem | null>(null);

  const [itemIndex, setItemIndex] = useState(0);
  const [cardLength, setCardLength] = useState(0);

  useEffect(() => {
    if (cards?.length > 0) {
      setCardList(cards);
      setSelectedCard(cards[0]);

      setItemIndex(0);
      setCardLength(cards?.length);
    } else {
      setCardList([]);
      setSelectedCard(null);

      setItemIndex(-1);
      setCardLength(-1);
    }
  }, [cards]);

  const onClickNextItem = () => {
    if (itemIndex + 1 < cardLength) {
      setSelectedCard(cards[itemIndex + 1]);
      setItemIndex(itemIndex + 1);
    }
  };

  const onClickBeforeItem = () => {
    if (itemIndex > 0) {
      setSelectedCard(cards[itemIndex - 1]);
      setItemIndex(itemIndex - 1);
    }
  };

  return (
    <div className="gcb-chat-item left-message">
      <Row justify="start">
        <Col className="text-area" span={20}>
          <p>{text}</p>
        </Col>
      </Row>

      {selectedCard && (
        <Row className="gcb-card-message-row" justify="center" align="middle">
          <Col className="" span={4}>
            {cardLength !== -1 && itemIndex > 0 && (
              <Button
                className="gcb-q-card-icons gcb-right-l-btn"
                type="primary"
                shape="circle"
                icon={<LeftOutlined />}
                onClick={onClickBeforeItem}
              />
            )}
          </Col>
          <Col className="gcb-card-message-box" span={16}>
            <img src={selectedCard?.imgUrl} alt="img" />

            <div className="gcb-card-text-box">
              <div className="c-head-div">
                <p className="c-head">{selectedCard?.headText}</p>
                <p className="c-sub">{selectedCard?.subText}</p>
              </div>

              {selectedCard?.answerList?.map((item: QuestionItem) => {
                return (
                  <Col
                    key={item?.id}
                    className="gcb-l-answer-box-btn"
                    span={24}
                    onClick={() => onClickBtnMessage(item)}
                  >
                    <span>{item?.answer}</span>
                  </Col>
                );
              })}
            </div>
          </Col>
          <Col span={4}>
            {cardLength !== -1 && itemIndex + 1 < cardLength && (
              <Button
                className="gcb-q-card-icons gcb-right-r-btn"
                type="primary"
                shape="circle"
                icon={<RightOutlined />}
                onClick={onClickNextItem}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
