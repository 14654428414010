export interface QuestionItem {
  id: string;
  answer: string;
}

export interface CardItem {
  id: string;
  headText: string;
  subText: string;
  imgUrl: string;
  answerList: QuestionItem[];
}

export enum MessageUserType {
  BOT = 'BOT',
  ME = 'ME',
}

export enum MessageType {
  NORMAL_TEXT = 'NORMAL_TEXT',
  NORMAL_IMG = 'NORMAL_IMG',
  QUESTION_LIST = 'QUESTION_LIST',
  CARD_LIST = 'CARD_LIST',
}

export interface MessageItem {
  id: string;
  type: MessageType;
  userType?: MessageUserType;
  text?: string;
  imgUrl?: string;
  answerList?: QuestionItem[];
  cardList?: CardItem[];
  errorMessage?: boolean;
}

export interface SendTextMessage {
  lastMessageId: string;
  message: string;
  publicId: string;
}
