import { Col, Row } from 'antd';
import { MessageUserType } from '../../models/chatItem';

interface NormalTextMessageProps {
  type?: MessageUserType;
  text: String;
}

export const NormalTextMessage = ({ type = MessageUserType.BOT, text }: NormalTextMessageProps) => {
  return (
    <div className={`gcb-chat-item ${type === 'BOT' ? 'left-message' : 'right-message'}`}>
      <Row justify={`${type === MessageUserType.BOT ? 'start' : 'end'}`}>
        <Col className="text-area" span={20}>
          <p>{text}</p>
        </Col>
      </Row>
    </div>
  );
};
