import { WechatOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './chat-icon.scss';

interface ChatIconProps {
  toggleChatButton: () => void;
  chatIconRef: any;
}

export const ChatIcon = ({ toggleChatButton, chatIconRef }: ChatIconProps) => {
  return (
    <div ref={chatIconRef} className="gcb-icon-div">
      <Button
        className="gcb-icon-button"
        type="primary"
        shape="circle"
        icon={<WechatOutlined style={{ fontSize: '30px' }} />}
        onClick={toggleChatButton}
      />
    </div>
  );
};
