import { Col, Row } from 'antd';

export const ChatLoadingMessage = () => {
  return (
    <div className="gcb-chat-item left-message">
      <Row justify="start">
        <Col className="text-loading-area" span={5}>
          <img src="/images/chat-loading.gif" alt="" />
        </Col>
      </Row>
    </div>
  );
};
