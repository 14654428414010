import { Col, Row } from 'antd';

import { QuestionItem } from '../../models/chatItem';

import './question-list-message.scss';

interface QuestionListMessageProps {
  text: string;
  answerList: QuestionItem[];
  onClickBtnMessage: (answerItem: QuestionItem) => void;
}

export const QuestionListMessage = ({ text, answerList, onClickBtnMessage }: QuestionListMessageProps) => {
  return (
    <div className="gcb-chat-item left-message">
      <Row justify="start">
        <Col className="text-area" span={20}>
          <p>{text}</p>
        </Col>

        {answerList?.map((item: QuestionItem) => {
          return (
            <Col key={item?.id} className="gcb-answer-box-btn" span={20} onClick={() => onClickBtnMessage(item)}>
              <span>{item?.answer}</span>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
