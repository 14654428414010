import { Col, Row } from 'antd';

interface ImageMessageProps {
  type?: 'BOT' | 'ME';
  imgUrl: string;
}

export const ImageMessage = ({ type = 'BOT', imgUrl }: ImageMessageProps) => {
  return (
    <div className={`gcb-chat-item ${type === 'BOT' ? 'left-message' : 'right-message'}`}>
      <Row justify={`${type === 'BOT' ? 'start' : 'end'}`}>
        <Col className="img-area" span={20}>
          <img src={imgUrl} alt="img" />
        </Col>
      </Row>
    </div>
  );
};
