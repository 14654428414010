const CHAR_LIST = 'ABCDEFGHIJKLMabcdefghijkl01234567789';

export const randomStr = (len = 6) => {
  let ans = '';
  for (let i = len; i > 0; i--) {
    ans += CHAR_LIST[Math.floor(Math.random() * CHAR_LIST.length)];
  }

  return ans;
};
