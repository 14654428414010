import { createRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import { ChatBox } from './components/ChatBox/ChatBox';
import { ChatIcon } from './components/ChatIcon/ChatIcon';
import { createChat } from './api/chat-api';

function App() {
  // const search = useLocation().search;

  const chatBoxRef: any = createRef();
  const chatIconRef: any = createRef();

  const [botId, setBotId] = useState('');
  const [publicId, setPublicId] = useState(null);

  const [isChatAppOpen, setIsChatAppOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    if (id) {
      setBotId(id);
    }
  }, []);

  const toggleChatButton = () => {
    if (!isChatAppOpen) {
      createChat(botId).then((data) => {
        try {
          if (data && data?.publicId) {
            setPublicId(data?.publicId);
          } else {
          }
        } catch (error) {}
      });
    }

    setIsChatAppOpen(!isChatAppOpen);
  };

  const handleSize = (height: any, width: any) => {
    const heightUpdateEvent = new CustomEvent('heightUpdate', {
      detail: { boxHeight: height, boxWidth: width },
    });
    window.dispatchEvent(heightUpdateEvent);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const w = urlParams.get('w');
    const h = urlParams.get('h');

    if (isMobile) {
      handleSize(h, w);
    } else if (chatBoxRef?.current?.clientHeight && chatBoxRef?.current?.clientWidth) {
      handleSize(chatBoxRef?.current?.clientHeight + 30, chatBoxRef?.current?.clientWidth + 30);
    }
  }, [chatBoxRef]);

  useEffect(() => {
    if (isMobile) {
      // handleSize(chatBoxRef?.current?.clientHeight + 30, chatBoxRef?.current?.clientWidth + 30);
    } else if (chatIconRef?.current?.clientHeight && chatIconRef?.current?.clientWidth) {
      handleSize(chatIconRef?.current?.clientHeight + 100, chatIconRef?.current?.clientWidth + 30);
    }
  }, [chatIconRef]);

  return (
    <div>
      {isChatAppOpen ? (
        <ChatBox publicId={publicId} toggleChatButton={toggleChatButton} chatBoxRef={chatBoxRef} />
      ) : (
        <ChatIcon toggleChatButton={toggleChatButton} chatIconRef={chatIconRef} />
      )}
    </div>
  );
}

export default App;
