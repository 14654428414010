import { createRef, useEffect, useRef, useState } from 'react';

import { Button, Col, Row, Input } from 'antd';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';

import { isMobile } from 'react-device-detect';

import { NormalTextMessage } from '../messages/NormalTextMessage';
import { ImageMessage } from '../messages/ImageMessage';
import { QuestionListMessage } from '../messages/QuestionListMessage';
import { CardListMessage } from '../messages/CardListMessage';
import { ChatLoadingMessage } from '../messages/ChatLoadingMessage';
import { chatInitialize, sendButtonMessage, sendTextMessage } from '../../api/chat-api';
import { MessageItem, MessageType, MessageUserType, QuestionItem } from '../../models/chatItem';
import { randomStr } from '../../utils/generate';

import './chat-box.scss';

const { TextArea } = Input;

interface ChatBoxProps {
  publicId: string | null;
  toggleChatButton: () => void;
  chatBoxRef: any;
}

export const ChatBox = ({ publicId, toggleChatButton, chatBoxRef }: ChatBoxProps) => {
  const bottomRef: any = useRef(null);

  const headerTopRef: any = createRef();
  const bottomInputRef: any = createRef();

  const [extraHeight, setExtraHeight] = useState(0);

  const [chatHistory, setChatHistory] = useState<MessageItem[]>([]);
  const [lastBotMessageId, setLastBotMessageId] = useState<string>('');

  const [loadingMessage, setLoadingMessage] = useState<boolean>(true);

  const [textInput, setTextInput] = useState<string>('');

  useEffect(() => {
    if (headerTopRef?.current?.clientHeight && bottomInputRef?.current?.clientHeight) {
      setExtraHeight(headerTopRef?.current?.clientHeight + bottomInputRef?.current?.clientHeight);
    }
  }, [headerTopRef, bottomInputRef]);

  useEffect(() => {
    if (publicId) {
      chatInitialize().then((data) => {
        try {
          if (data && data?.length > 0) {
            pushNewMessages(data);
          } else {
            setChatHistory([]);
          }
        } catch (error) {
          setChatHistory([]);
        }
      });
    }
  }, [publicId]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sleep = async (ms: number) => new Promise((res) => setTimeout(res, ms));

  const onClickSendBtn = () => {
    setLoadingMessage(true);
    scrollToBottom();

    if (publicId) {
      if (textInput !== null && textInput !== '') {
        chatHistory.push({
          id: randomStr(),
          type: MessageType.NORMAL_TEXT,
          text: textInput,
          userType: MessageUserType.ME,
        });

        let message = textInput;
        scrollToBottom();
        setTextInput('');

        sendTextMessage({ lastMessageId: lastBotMessageId, message, publicId }).then((data) => {
          try {
            if (data && data?.length > 0) {
              pushNewMessages(data);
            }
          } catch (error) {}
        });
      }
    }
  };

  const onClickBtnMessage = (answerItem: QuestionItem) => {
    setLoadingMessage(true);
    scrollToBottom();

    if (publicId && answerItem !== null && answerItem?.id) {
      chatHistory.push({
        id: randomStr(),
        type: MessageType.NORMAL_TEXT,
        text: answerItem?.answer,
        userType: MessageUserType.ME,
      });

      scrollToBottom();

      sendButtonMessage(answerItem?.id, publicId).then((data) => {
        try {
          if (data && data?.length > 0) {
            pushNewMessages(data);
          }
        } catch (error) {}
      });
    }
  };

  const pushNewMessages = async (data: MessageItem[]) => {
    for (let i = 0; i < data.length; i++) {
      setLoadingMessage(true);
      await sleep(1000);

      setLoadingMessage(false);
      scrollToBottom();

      chatHistory.push(data[i]);

      if (!data[i]?.errorMessage) {
        setLastBotMessageId(data[i]?.id);
      }
    }
  };

  const renderMessageItem = (item: MessageItem) => {
    if (item?.type === MessageType.NORMAL_TEXT) {
      return (
        <NormalTextMessage
          key={item?.id}
          text={item?.text ? item.text : ''}
          type={item?.userType ? item?.userType : MessageUserType.BOT}
        />
      );
    }

    if (item?.type === MessageType.NORMAL_IMG) {
      return <ImageMessage key={item?.id} imgUrl={item?.imgUrl ? item?.imgUrl : ''} />;
    }

    if (item?.type === MessageType.QUESTION_LIST) {
      return (
        <QuestionListMessage
          key={item?.id}
          text={item?.text ? item?.text : ''}
          answerList={item?.answerList && item?.answerList?.length > 0 ? item?.answerList : []}
          onClickBtnMessage={onClickBtnMessage}
        />
      );
    }

    if (item?.type === MessageType.CARD_LIST) {
      return (
        <CardListMessage
          key={item?.id}
          text={item?.text ? item?.text : ''}
          cards={item?.cardList && item?.cardList?.length > 0 ? item?.cardList : []}
          onClickBtnMessage={onClickBtnMessage}
        />
      );
    }

    return null;
  };

  return (
    <div ref={chatBoxRef} className={`gcb-chat-box ${isMobile ? 'mobile-screen-chat-box' : 'large-screen-chat-box'}`}>
      <div ref={headerTopRef} className="gcb-chat-header">
        {/* <Row className="min-header" align="middle">
          <Col span={20}>
            <img src="/images/logo.png" alt="logo" />
            <h1>VIDA Chat Bot</h1>
          </Col>
          <Col span={4}>
            <Button
              className="gcb-chat-close-button"
              type="primary"
              icon={<CloseOutlined />}
              onClick={toggleChatButton}
            />
          </Col>
        </Row> */}

        <div className="gcb-top-btn">
          <Button
            className="gcb-chat-close-button float-close-btn"
            type="primary"
            icon={<CloseOutlined />}
            onClick={toggleChatButton}
          />
        </div>
        <div className="gcb-chat-title">
          <img src="/images/logo.png" alt="logo" />
          <h1>VIDA Chat Bot</h1>
          <p>Supporting for your needs</p>
        </div>
      </div>

      <div
        className="gcb-chat-container"
        style={{ height: isMobile ? `calc(${window.innerHeight}px - 30px - ${extraHeight}px)` : '' }}
      >
        {chatHistory?.map((item: MessageItem) => {
          return renderMessageItem(item);
        })}

        {loadingMessage && <ChatLoadingMessage />}

        <div ref={bottomRef} />
      </div>
      <div ref={bottomInputRef} className="gcb-chat-input">
        <Row>
          <Col span={21} className="gcb-input-col">
            <TextArea
              className="text-input"
              placeholder="Write a message"
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={textInput}
              onChange={(e) => {
                setTextInput(e?.target?.value);
              }}
              onPressEnter={(e) => {
                onClickSendBtn();

                e.preventDefault();
              }}
            />
          </Col>
          <Col span={3} className="gcb-input-col">
            <Button className="gcb-send-message-btn" type="primary" icon={<SendOutlined />} onClick={onClickSendBtn} />
          </Col>
        </Row>
      </div>
    </div>
  );
};
