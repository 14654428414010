import axios from 'axios';
import { SendTextMessage } from '../models/chatItem';

export const createChat = async (botId: string) => {
  try {
    let payload = JSON.stringify({
      botPublicId: botId,
    });

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/v1/chat`,
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': `application/json`,
      },
    });

    return data;
  } catch (error: any) {
    return null;
  }
};

export const chatInitialize = async () => {
  try {
    let payload = JSON.stringify({});

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/v1/chat/initial`,
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': `application/json`,
      },
    });

    return data;
  } catch (error: any) {
    return null;
  }
};

export const sendTextMessage = async ({ lastMessageId, message, publicId }: SendTextMessage) => {
  try {
    let payload = JSON.stringify({ lastMessageId, message, publicId });

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/v1/chat/send-text`,
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': `application/json`,
      },
    });

    return data;
  } catch (error: any) {
    return null;
  }
};

export const sendButtonMessage = async (buttonId: string, publicId: string) => {
  try {
    let payload = JSON.stringify({ buttonId, publicId });

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/v1/chat/button`,
      data: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': `application/json`,
      },
    });

    return data;
  } catch (error: any) {
    return null;
  }
};
